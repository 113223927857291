var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    [
      _c(
        "b-form",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Old Password",
                        "label-for": "account-old-password"
                      }
                    },
                    [
                      _c(
                        "b-input-group",
                        { staticClass: "input-group-merge" },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "account-old-password",
                              name: "old-password",
                              type: _vm.passwordFieldTypeOld,
                              placeholder: "Old Password"
                            },
                            model: {
                              value: _vm.passwordValueOld,
                              callback: function($$v) {
                                _vm.passwordValueOld = $$v
                              },
                              expression: "passwordValueOld"
                            }
                          }),
                          _c(
                            "b-input-group-append",
                            { attrs: { "is-text": "" } },
                            [
                              _c("feather-icon", {
                                staticClass: "cursor-pointer",
                                attrs: { icon: _vm.passwordToggleIconOld },
                                on: { click: _vm.togglePasswordOld }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        "label-for": "account-new-password",
                        label: "New Password"
                      }
                    },
                    [
                      _c(
                        "b-input-group",
                        { staticClass: "input-group-merge" },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "account-new-password",
                              type: _vm.passwordFieldTypeNew,
                              name: "new-password",
                              placeholder: "New Password"
                            },
                            model: {
                              value: _vm.newPasswordValue,
                              callback: function($$v) {
                                _vm.newPasswordValue = $$v
                              },
                              expression: "newPasswordValue"
                            }
                          }),
                          _c(
                            "b-input-group-append",
                            { attrs: { "is-text": "" } },
                            [
                              _c("feather-icon", {
                                staticClass: "cursor-pointer",
                                attrs: { icon: _vm.passwordToggleIconNew },
                                on: { click: _vm.togglePasswordNew }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        "label-for": "account-retype-new-password",
                        label: "Retype New Password"
                      }
                    },
                    [
                      _c(
                        "b-input-group",
                        { staticClass: "input-group-merge" },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "account-retype-new-password",
                              type: _vm.passwordFieldTypeRetype,
                              name: "retype-password",
                              placeholder: "New Password"
                            },
                            model: {
                              value: _vm.RetypePassword,
                              callback: function($$v) {
                                _vm.RetypePassword = $$v
                              },
                              expression: "RetypePassword"
                            }
                          }),
                          _c(
                            "b-input-group-append",
                            { attrs: { "is-text": "" } },
                            [
                              _c("feather-icon", {
                                staticClass: "cursor-pointer",
                                attrs: { icon: _vm.passwordToggleIconRetype },
                                on: { click: _vm.togglePasswordRetype }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "ripple",
                          rawName: "v-ripple.400",
                          value: "rgba(255, 255, 255, 0.15)",
                          expression: "'rgba(255, 255, 255, 0.15)'",
                          modifiers: { "400": true }
                        }
                      ],
                      staticClass: "mt-1 mr-1",
                      attrs: { variant: "primary" }
                    },
                    [_vm._v(" Save changes ")]
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "ripple",
                          rawName: "v-ripple.400",
                          value: "rgba(186, 191, 199, 0.15)",
                          expression: "'rgba(186, 191, 199, 0.15)'",
                          modifiers: { "400": true }
                        }
                      ],
                      staticClass: "mt-1",
                      attrs: { type: "reset", variant: "outline-secondary" }
                    },
                    [_vm._v(" Reset ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }