var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    [
      _c(
        "b-media",
        { attrs: { "no-body": "" } },
        [
          _c(
            "b-media-aside",
            [
              _c(
                "b-link",
                [
                  _c("b-img", {
                    ref: "previewEl",
                    attrs: {
                      rounded: "",
                      src: _vm.optionsLocal.avatar,
                      height: "80"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-media-body",
            { staticClass: "mt-75 ml-75" },
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(255, 255, 255, 0.15)",
                      expression: "'rgba(255, 255, 255, 0.15)'",
                      modifiers: { "400": true }
                    }
                  ],
                  staticClass: "mb-75 mr-75",
                  attrs: { variant: "primary", size: "sm" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.refInputEl.$el.click()
                    }
                  }
                },
                [_vm._v(" Upload ")]
              ),
              _c("b-form-file", {
                ref: "refInputEl",
                attrs: { accept: ".jpg, .png, .gif", hidden: true, plain: "" },
                on: { input: _vm.inputImageRenderer },
                model: {
                  value: _vm.profileFile,
                  callback: function($$v) {
                    _vm.profileFile = $$v
                  },
                  expression: "profileFile"
                }
              }),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(186, 191, 199, 0.15)",
                      expression: "'rgba(186, 191, 199, 0.15)'",
                      modifiers: { "400": true }
                    }
                  ],
                  staticClass: "mb-75 mr-75",
                  attrs: { variant: "outline-secondary", size: "sm" }
                },
                [_vm._v(" Reset ")]
              ),
              _c("b-card-text", [
                _vm._v("Allowed JPG, GIF or PNG. Max size of 800kB")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-form",
        { staticClass: "mt-2" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Username",
                        "label-for": "account-username"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: { placeholder: "Username", name: "username" },
                        model: {
                          value: _vm.optionsLocal.username,
                          callback: function($$v) {
                            _vm.$set(_vm.optionsLocal, "username", $$v)
                          },
                          expression: "optionsLocal.username"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { sm: "6" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Name", "label-for": "account-name" } },
                    [
                      _c("b-form-input", {
                        attrs: { name: "name", placeholder: "Name" },
                        model: {
                          value: _vm.optionsLocal.fullName,
                          callback: function($$v) {
                            _vm.$set(_vm.optionsLocal, "fullName", $$v)
                          },
                          expression: "optionsLocal.fullName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { sm: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: { label: "E-mail", "label-for": "account-e-mail" }
                    },
                    [
                      _c("b-form-input", {
                        attrs: { name: "email", placeholder: "Email" },
                        model: {
                          value: _vm.optionsLocal.email,
                          callback: function($$v) {
                            _vm.$set(_vm.optionsLocal, "email", $$v)
                          },
                          expression: "optionsLocal.email"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { sm: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Company",
                        "label-for": "account-company"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: { name: "company", placeholder: "Company name" },
                        model: {
                          value: _vm.optionsLocal.company,
                          callback: function($$v) {
                            _vm.$set(_vm.optionsLocal, "company", $$v)
                          },
                          expression: "optionsLocal.company"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "mt-75", attrs: { cols: "12" } },
                [
                  _c(
                    "b-alert",
                    {
                      staticClass: "mb-50",
                      attrs: { show: "", variant: "warning" }
                    },
                    [
                      _c("h4", { staticClass: "alert-heading" }, [
                        _vm._v(
                          " Your email is not confirmed. Please check your inbox. "
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "alert-body" },
                        [
                          _c("b-link", { staticClass: "alert-link" }, [
                            _vm._v(" Resend confirmation ")
                          ])
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "ripple",
                          rawName: "v-ripple.400",
                          value: "rgba(255, 255, 255, 0.15)",
                          expression: "'rgba(255, 255, 255, 0.15)'",
                          modifiers: { "400": true }
                        }
                      ],
                      staticClass: "mt-2 mr-1",
                      attrs: { variant: "primary" }
                    },
                    [_vm._v(" Save changes ")]
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "ripple",
                          rawName: "v-ripple.400",
                          value: "rgba(186, 191, 199, 0.15)",
                          expression: "'rgba(186, 191, 199, 0.15)'",
                          modifiers: { "400": true }
                        }
                      ],
                      staticClass: "mt-2",
                      attrs: { variant: "outline-secondary", type: "reset" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.resetForm($event)
                        }
                      }
                    },
                    [_vm._v(" Reset ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }